

html, body {
    min-height: 100vh;
    margin: 0;
    padding: 0;
}

.root-login {
    min-height: 100vh;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url('../assets/itp.jpeg') no-repeat center center fixed;
    background-size: cover;
}

.container-login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.logo {
    margin-bottom: 20px;
    width: 150px;
}

.login-div {
    /* background: rgba(255, 255, 255, 0.8);
    padding: 30px;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
    background: rgba(255, 255, 255, 0.8);
    padding: 30px;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    width: 90%;
    max-width: 400px;
}

.login-div img {
    width: 150px;
    margin-bottom: 20px;
}

.login-btn {
    margin-top: 20px;
}

h1 {
    font-family: 'Arial', sans-serif;
    font-size: 2em;
    color: #2c3e50;
    /* color: red; */
    margin-bottom: 20px;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    font-size: 3em;
    color: #024075;
}


.google-login-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}