body {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .navbar {
    background-color: #024075;
    height: 80px;
    display: flex;
    align-items: center;
  }
  
  .menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
  }
  
  .nav-menu {
    width: 250px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    background-color: #024075;
  }
  
  .nav-menu.active {
    left: 0;
    transition: 350ms;
    z-index: 10;
  }
  
  .nav-menu-items {
    width: 100%;
  }
  
  .navbar-toggle {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  .nav-text {
    padding: 8px 16px;
  }
  
  .sub-nav {
    display: none;
  }
  
  .sub-nav a {
    padding-left: 3rem;
  }
  
  .sub-nav a:hover {
    background-color: #1a83ff;
  }
  
  .main-wrapper {
    /* margin-left: 250px; */
    padding: 20px;
    transition: margin-left 350ms;
  }
  
  .main-wrapper.shrink {
    margin-left: 0;
  }

  .nav-link:hover {
    background-color: rgba(255, 255, 255, 0.1);  
  }
